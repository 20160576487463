import React, { useEffect } from 'react';
import BaseHomePage from '@/components/home/HomePage';
import { getHomeServerProps } from '@/utils';

export const getServerSideProps = getHomeServerProps;

const HomePage = serverProps => {
  const { city, locations, ambassadors, uniEvents, ipAddress } = serverProps;

  // useEffect(() => {
  //   // Redirect to the closest city if the user has not selected a city
  //   const localCityKey = localStorage.getItem('CityKey');
  //   if (localCityKey) {
  //     router.push(links.CITY(localCityKey));
  //   } else if (closestCityKey) {
  //     router.push(links.CITY(closestCityKey));
  //   }
  // }, [closestCityKey]);

  return <BaseHomePage {...{ city, locations, ambassadors, uniEvents, ipAddress }} />;
};

export default HomePage;

HomePage.pageNameCity = 'Home';
HomePage.pageName = 'Home';
